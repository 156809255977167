import { TableRow, TableCell } from '@awell-health/design-system'
import { navigate } from '@reach/router'
import React, { FC } from 'react'
import { Patient } from '../types'
import { Search } from '@awell/ui-kit/components/Icons'
import { LinkCell } from '@awell/ui-kit/components/Table/LinkCell'
import { defaultProfilePropTo, formatDate } from '../../../utils'
import { IconButton } from '@awell/ui-kit/components/Button/IconButton'
import { useTranslation } from 'react-i18next'

interface PatientTableRowProps {
  patient: Patient
}

export const PathwayTableRow: FC<PatientTableRowProps> = ({ patient }) => {
  const { t } = useTranslation()

  return (
    <TableRow>
      <TableCell>
        <LinkCell
          title={defaultProfilePropTo({
            prop: 'name',
            defaultValue: t('anonymous'),
          })(patient)}
          id={patient.id}
        />
      </TableCell>
      <TableCell>
        {defaultProfilePropTo({ prop: 'patient_code' })(patient)}
      </TableCell>
      <TableCell>
        {defaultProfilePropTo({
          prop: 'birth_date',
          formatFn: formatDate('dd/MM/yyyy'),
        })(patient)}
      </TableCell>
      <TableCell>{defaultProfilePropTo({ prop: 'email' })(patient)}</TableCell>
      <TableCell>
        {defaultProfilePropTo({ prop: 'preferred_language' })(patient)}
      </TableCell>
      <TableCell>
        <IconButton
          tooltip={t('view_activities')}
          iconSize='xs'
          onClick={() => {
            void navigate(`/activities/patient/${patient.id}`)
          }}
        >
          <Search />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
