import {
  type RouteComponentProps,
  Router,
  useLocation,
  useNavigate,
} from '@reach/router'
import React, { type FC, useEffect, useState } from 'react'
import { useBreadcrumbs } from '@awell/ui-kit/hooks/useBreadcrumbs'
import { LinkTab } from '@awell/ui-kit/components/Tabs'
import { useTranslation } from 'react-i18next'
import { isNil } from 'lodash'
import { usePatient } from '../../../hooks/usePatient'
import { PatientProfileCard } from '../../../components/PatientProfileCard'
import { ActivePathwaysCard } from '../../../components/ActivePathwaysCard'
import { useStyles } from '../useStyles'
import { SettingsTab } from './SettingsTab'
import { ProfileTab } from './ProfileTab'
import {
  type StartPathwayLabels,
  StartPathwayModal,
} from '../../../components/StartPathwayDialog'
import { type StartPathwayInput } from '../types'
import { useStartPathway } from '../../../hooks/useStartPathway'
import { PatientPathwaysTab } from './PatientPathwaysTab'
import { defaultProfilePropTo } from '../../../utils'
import { PatientPageSkeleton } from './skeletons'
import { ActivitiesTab } from './ActivitiesTab'
import { useTrackCustify } from '@awell/libs-web/analytics'

interface PatientPageParams {
  patientId: string
}

export const PatientPage: FC<RouteComponentProps<PatientPageParams>> = ({
  patientId,
}) => {
  const { loading, patient } = usePatient(patientId!)
  const { startPathway } = useStartPathway()
  const { addBreadcrumbs, updateBreadcrumb } = useBreadcrumbs()
  const classes = useStyles()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const trackCustify = useTrackCustify()
  const isCurrentTabPath = (tabName: string): boolean =>
    pathname.includes(tabName)

  const isPatientProfileRoute: boolean = isCurrentTabPath('/patients')

  const [startPathwayModalOpen, setStartPathwayModalOpen] =
    useState<boolean>(false)

  const handleStartPathway = (
    data: Omit<StartPathwayInput, 'patient_id'>,
  ): void => {
    void startPathway({ patient_id: patientId!, ...data })
    void trackCustify('careflow_started_care', {
      careflow_definition_id: data.pathway_definition_id,
      patient_id: patientId,
    })
    setStartPathwayModalOpen(false)
  }

  const handleOpenStartPathwayModal = (): void => {
    setStartPathwayModalOpen(true)
  }

  const handleCloseStartPathwayModal = (): void => {
    setStartPathwayModalOpen(false)
  }

  const handleViewAllActivePathways = async (): Promise<void> => {
    await navigate(`/patients/${patientId!}/pathways`)
  }

  const handleViewPathway = async (id: string): Promise<void> => {
    await navigate(`/pathway/${id}`)
  }

  const patientName = defaultProfilePropTo({
    prop: 'name',
    defaultValue: t('anonymous'),
  })(patient!)

  const breadcrumb = {
    label: patientName,
    url: `/patients/${patientId!}`,
    level: 1,
  }

  useEffect(() => {
    // return statement needed to not break breadcrumbs when navigating between pages
    return addBreadcrumbs(breadcrumb)
  }, [])

  useEffect(() => {
    updateBreadcrumb(breadcrumb)
  }, [patientName])

  const startPathwayModalLabels: StartPathwayLabels = {
    heading: t('start_pathway_for_patient', { patientName }),
    primaryAction: t('start_pathway'),
    secondaryAction: t('cancel'),
  }

  if (loading) {
    return <PatientPageSkeleton />
  }

  if (isNil(patient)) {
    return <h1>{t('patient_not_found')}</h1>
  }

  return (
    <div className={classes.profilePageContainer}>
      <div className={classes.profileCardsSection}>
        <PatientProfileCard
          patient={patient}
          onStartPathway={handleOpenStartPathwayModal}
        />
        <ActivePathwaysCard
          patientId={patientId!}
          onViewAll={handleViewAllActivePathways}
          onViewPathway={handleViewPathway}
        />
      </div>
      <div className={classes.profileContentSection}>
        <div className={classes.tabsContainer}>
          <LinkTab
            tabLabel={t('patient_activities')}
            selected={isCurrentTabPath('/activities')}
            linkToLocation='activities'
          />

          <LinkTab
            tabLabel={t('profile')}
            selected={isPatientProfileRoute}
            linkToLocation=''
          />
          <LinkTab
            tabLabel={t('pathways')}
            selected={isCurrentTabPath('/pathways')}
            linkToLocation='pathways'
          />

          <LinkTab
            tabLabel={t('settings')}
            selected={isCurrentTabPath('/settings')}
            linkToLocation='settings'
          />
        </div>
        <div className={classes.routeContainer}>
          {/* Primary Routers will manage focus on location transitions so we set
           // it to false here, otherwise it scrolls to the inner content on load */}
          <Router primary={false}>
            <ProfileTab patient={patient} path='/' />
            <ActivitiesTab patient={patient} path='activities' />
            <SettingsTab patientName={patientName} path='settings' />
            <PatientPathwaysTab
              patientId={patientId}
              patientName={patientName}
              path='pathways'
            />
          </Router>
        </div>
      </div>
      <StartPathwayModal
        open={startPathwayModalOpen}
        labels={startPathwayModalLabels}
        onAction={handleStartPathway}
        onClose={handleCloseStartPathwayModal}
      />
    </div>
  )
}
